@import url('https://fonts.googleapis.com/css?family=Roboto');

/* Global Styles */
:root {
  --primary-color: #2c6892;
  /*--dark-color: #3a3c9f;*/
  --dark-color: #333;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #00ad7e;
  --warning-color: #e49a3a;
  --gray-color: #6c757d;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #666;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.medium {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  white-space: nowrap;
}

.smaller {
  font-size: 1rem;
  color: #333;
}

.lead {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-primary-faded {
  color: rgba(44, 104, 146, 0.8);
}

.text-success {
  color: var(--success-color);
}

.text-success-faded {
  color: rgba(0, 173, 126, 0.6);
}

.text-warning {
  color: var(--warning-color);
}
.text-warning-invert {
  color: var(--dark-color);
}

.text-warning-faded {
  color: rgba(228, 154, 58, 0.8);
}

.text-dark {
  color: var(--dark-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-indent {
  text-indent: 10px;
}

/* Cards */
.card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
}

/* List */
.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}
.mt-1 {
  margin-top: 1rem;
}

/* Grid */
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-1-btn {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1rem;
}
.grid-2-btn {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-gap: 1rem;
}

.grid-2-lean {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
.grid-3-btn {
  display: grid;
  grid-template-columns: 3fr 1fr 2fr;
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}
.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
}
.grid-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1.5rem;
}
.grid-7 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1.5rem;
}
.grid-2-3 {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 2rem;
}
/* .grid-pancake {
  display: grid;
  grid-template-rows: auto 1fr auto;
} */
.fa-stack2 {
  display: inline-block;
  height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}

.stackTrans {
  color: rgba(255, 0, 0, 0.5);
}
.center-items {
  display: grid;
  place-items: center;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.badge {
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-warning,
.bg-warning,
.badge-warning,
.alert-warning {
  background: var(--warning-color);
  color: #000;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn-warning {
  background: var(--warning-color);
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.bg-success-opac {
  background-color: var(--success-color);
  color: #fff;
  opacity: 0.65;
  text-rendering: ;
}
.bg-gray {
  background-color: var(--gray-color);
  opacity: 100;
}

.round-img {
  border-radius: 50%;
}

/* Forms */
input {
  margin: 1.2rem 0;
}

.inputMin {
  margin: 0px;
}

.input-hidden {
  border-top-style: hidden !important;
  border-right-style: hidden !important;
  border-left-style: hidden !important;
  border-bottom-style: hidden !important;
}

.input-hidden:focus {
  outline: none;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.centrik-url-button {
  position: absolute;
  width: 100px;
  height: 40px;
  margin: auto;
  border: none;
  right: 25px;
  bottom: 35px;
  border-radius: 5px;
  background-color: #484950;
}

.project-description {
  margin-left: 1em;
  font-weight: bold;
}
.task-description {
  margin-left: 2em;
  font-style: italic;
  font-size: smaller;
}

input[type='submit'],
button {
  font: inherit;
}

.form-group {
  margin: 0.2rem 0;
}

.form-group input {
  margin: 0.2rem 0;
}

.form-container {
  max-width: 500px;
  margin: 2rem auto;
  overflow: hidden;
  padding: 0 2rem;
}

.form-container h1,
h2,
h3 {
  text-align: center;
}

table th,
table td {
  padding: 1rem;
  text-align: left;
}

table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
  margin-bottom: 1rem;
}

.options-list-grid-2 {
  display: grid;
  grid-template-columns: 1fr 6fr;
  word-break: break-all;
}

.options-list-grid-2:hover {
  /* opacity: 0.7; */
  background: lightgray;
}

.navbar-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  opacity: 0.9;
}
.navbar-bottom i {
  width: 100%;
  text-align: center;
}

i:hover {
  opacity: 0.7;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--light-color);
}

.navbar span {
  font-size: 1.5em;
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.results-list {
  border-bottom: solid 1px var(--primary-color);
  margin-bottom: 0.5rem;
}

/* Animation (Add After) */
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
.description {
  font-style: italic;
  font-size: smaller;
  word-wrap: normal;
}

.icon-description {
  font-style: italic;
  font-size: small;
  word-wrap: normal;
}

.inner-scroll {
  overflow-y: auto;
  height: 70vh;
}

.inner-scroll-small {
  overflow-y: auto;
  height: 70%;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 1rem;
  }

  .icon-description {
    font-size: x-small;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar h1 {
    margin-bottom: 0.6rem;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }
  .input-wrapper {
    position: relative;
  }
  .input-wrapper:after {
    font-family: 'FontAwesome';
    content: '\f274';
    position: absolute;
    right: 6px;
  }

  .input-icon {
    position: absolute;
    left: 3px;
    top: calc(
      50% - 0.5em
    ); /* Keep icon in center of input, regardless of the input height */
  }
  input {
    padding-left: 17px;
  }
  .input-wrapper {
    position: relative;
  }

  .inner-scroll {
    overflow: scroll;
    height: 60vh;
  }
}
.dropdown-menu {
  height: 350px;
  overflow-y: scroll;
}
.ulVert {
  list-style-type: disc !important;
  padding-left: 1em !important;
  margin-left: 1em;
}

.datepicker-custom {
  border: 1px solid !important;
  border-radius: 2px !important;
  height: 28px !important;
  width: 175px !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  padding-left: 1px !important;
}

.datepicker-custom-large {
  border: 1px solid !important;
  border-radius: 2px !important;
  height: 40px !important;
  width: 187px !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  padding-left: 1px !important;
}

.select-custom {
  border: 1px solid !important;
  border-radius: 2px !important;
  height: 30px !important;
  width: 172px !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  padding-left: 1px !important;
  padding-bottom: 0px !important;
}

.select-custom-large {
  border: 1px solid !important;
  border-radius: 2px !important;
  height: 45px !important;
  width: 187px !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  padding-left: 1px !important;
  padding-bottom: 0px !important;
}

.select-custom-wrapper {
  display: flex;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}
